import React, { createContext, useContext, useState, ReactNode } from "react";
import { getPitchDeck } from "actions/api";

interface PitchDeckContextProps {
  documentLink: string | null;
  loadPitchDeck: (linkHash: string, investorEmail: string) => Promise<void>;
}

const PitchDeckContext = createContext<PitchDeckContextProps | undefined>(undefined);

interface PitchDeckProviderProps {
  children: ReactNode;
}

export const PitchDeckProvider: React.FC<PitchDeckProviderProps> = ({ children }) => {
  const [documentLink, setDocumentLink] = useState<string | null>(null);

  const loadPitchDeck = async (linkHash: string, investorEmail: string) => {
    try {
      const response = await getPitchDeck(linkHash, investorEmail);
      setDocumentLink(response.documentLink);
    } catch (error) {
      console.error("Failed to load pitch deck:", error);
    }
  };

  return (
    <PitchDeckContext.Provider value={{ documentLink, loadPitchDeck }}>
      {children}
    </PitchDeckContext.Provider>
  );
};

export const usePitchDeck = () => {
  const context = useContext(PitchDeckContext);
  if (!context) {
    throw new Error("usePitchDeck must be used within a PitchDeckProvider");
  }
  return context;
};
