import React from "react";
import { Route, Navigate } from "react-router-dom";

interface PrivateRouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const investorToken = localStorage.getItem("bummock-investor-email");
  const isAuthenticated = !!investorToken;

  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/sign-in" replace />;
};

export default PrivateRoute;
