/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// PitchDeckPage page components
import PitchDeckViewer from "./components/PitchDeck";
import { usePitchDeck } from "context/PitchDeckContext";

const PitchDeckPage = () => {
  const navigate = useNavigate();
  const { documentLink } = usePitchDeck();
  const { linkHash } = useParams<{ linkHash: string; investorEmail: string }>();

  useEffect(() => {
    if (!documentLink || !linkHash) {
      navigate(`/sign-in?linkHash=${linkHash}`);
    }
  }, [documentLink, linkHash]);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox py={3}>
        {documentLink ? (
          <Card sx={{ overflow: "visible" }}>
            <MDBox p={3}>
              <PitchDeckViewer presignedUrl={documentLink} />
            </MDBox>
          </Card>
        ) : (
          <h3>Page does not exist</h3>
        )}
      </MDBox>
    </DashboardLayout>
  );
};

export default PitchDeckPage;
