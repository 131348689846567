import axios from "axios";

const DUE_DILIGENCE_BASE_URL = process.env.REACT_APP_DUE_DILIGENCE_BASE_URL;
const REACT_APP_DUE_DILIGENCE_AUTHORIZATION = process.env.REACT_APP_DUE_DILIGENCE_AUTHORIZATION;

export const api = axios.create({
  baseURL: DUE_DILIGENCE_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: REACT_APP_DUE_DILIGENCE_AUTHORIZATION || "",
  },
});

interface PitchDeckResponse {
  investorCollaboration: any; // Replace with the actual type if known
  documentLink: string;
}

/**
 * Fetches the pitch deck for the given linkHash and investorEmail.
 * @param linkHash - The hash of the link to identify the collaboration.
 * @param investorEmail - The email of the investor.
 * @returns The data containing the investor collaboration details and the document link.
 * @throws Error if the request fails.
 */
export const getPitchDeck = async (
  linkHash: string,
  investorEmail: string
): Promise<PitchDeckResponse> => {
  try {
    const { data } = await api.get<PitchDeckResponse>(
      `/api/investor-collaboration/${linkHash}/${investorEmail}`
    );
    return data;
  } catch (error) {
    console.error(
      `Failed to fetch pitch deck for linkHash: ${linkHash} and investorEmail: ${investorEmail}`,
      error
    );
    throw new Error("Failed to fetch pitch deck");
  }
};
