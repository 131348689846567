import React, { useState, useEffect } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { pdfjs } from "react-pdf";
import { ArrowBack, ArrowForward, PlayArrow } from "@mui/icons-material";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const PitchDeckViewer = ({ presignedUrl }: { presignedUrl: string }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);

  const nextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages || 1));
  };

  const prevPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber - 1, 1));
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isPlaying) {
      interval = setInterval(() => {
        nextPage();
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [isPlaying, pageNumber, numPages]);

  const togglePlay = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f0f0f0",
        borderRadius: 2,
        overflow: "hidden",
        boxShadow: 3,
        position: "relative",
      }}
    >
      <Box
        component="iframe"
        title="Pitch Deck"
        src={presignedUrl + "#view=fit"}
        allowFullScreen
        sx={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          position: "absolute",
          bottom: 16,
          px: 2,
        }}
      >
        <IconButton onClick={prevPage} disabled={pageNumber <= 1}>
          <ArrowBack />
        </IconButton>
        <Button onClick={togglePlay}> {isPlaying ? "Pause" : <PlayArrow />}</Button>
        <IconButton onClick={nextPage} disabled={pageNumber >= (numPages || 1)}>
          <ArrowForward />
        </IconButton>
      </Box>
    </Box>
  );
};

export default PitchDeckViewer;
